import React, { useEffect, useState } from 'react';
import { Item, Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getStopwatchParts } from '../../utils/stopwatch';

export const StopwatchItem = React.memo(({ name, date, time, isYour, onDelete }) => {
  const [t] = useTranslation();
  const [hoursField, setHours] = useState(0);
  const [minutesField, setMinutes] = useState(0);
  useEffect(() => {
    const { hours, minutes } = getStopwatchParts({ total: time });
    setMinutes(minutes);
    setHours(hours);
  }, [time]);
  return (
    <Item
      style={{
        margin: '0px 0px 10px 0',
        padding: '4px 10px',
        backgroundColor: '#fafafa',
        borderRadius: '5px',
      }}
    >
      <Item.Content>
        <Item.Header>{name}</Item.Header>
        <Item.Meta>
          {t('format:date', {
            postProcess: 'formatDate',
            value: new Date(date),
          })}{' '}
          per {hoursField}h {minutesField}m
          {isYour && (
            <Icon
              as={Button}
              color="red"
              icon="trash"
              size="mini"
              onClick={() => onDelete}
              name="delete"
              align="right"
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
                padding: '1px 8px',
              }}
            />
          )}
        </Item.Meta>
      </Item.Content>
    </Item>
  );
});

export default StopwatchItem;
