import { dequal } from 'dequal';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { useToggle } from '../../lib/hooks';
import { Input, Popup } from '../../lib/custom-ui';
import { useForm } from '../../hooks';
import { createStopwatch, getStopwatchParts, updateStopwatch } from '../../utils/stopwatch';
import { StopwatchItem } from './StopwatchItem';
import styles from './StopwatchEditStep.module.scss';

const StopwatchEditStep = React.memo(
  ({ defaultValue, onUpdate, onBack, onClose, currentUserIds }) => {
    const [t] = useTranslation();
    const [data, handleFieldChange, setData] = useForm(() => ({
      hours: '0',
      minutes: '0',
      seconds: '0',
    }));
    const [isEditing, toggleEditing] = useToggle(true);

    const hoursField = useRef(null);
    const minutesField = useRef(null);

    useEffect(() => {
      console.log('defaultValue', defaultValue);
    }, [defaultValue]);

    const handleToggleEditingClick = useCallback(() => {
      setData(defaultValue);
      toggleEditing();
    }, [defaultValue, setData, toggleEditing]);

    const handleSubmit = useCallback(() => {
      const parts = {
        hours: parseInt(data.hours, 10),
        minutes: parseInt(data.minutes, 10),
        seconds: parseInt(data.seconds, 10),
      };
      if (defaultValue) {
        if (!dequal(parts, getStopwatchParts(defaultValue))) {
          onUpdate(updateStopwatch(defaultValue, parts));
        }
      } else {
        onUpdate(createStopwatch(parts));
      }

      onClose();
    }, [defaultValue, onUpdate, onClose, data]);

    useEffect(() => {
      console.log('currentUserIds', currentUserIds);
    }, [currentUserIds]);

    useEffect(() => {
      if (isEditing) {
        hoursField.current.select();
      }
    }, [isEditing]);

    return (
      <>
        <Popup.Header onBack={onBack}>Modifica consuntivo</Popup.Header>
        <Popup.Content>
          <Form onSubmit={handleSubmit}>
            <div className={styles.fieldWrapper}>
              <div className={styles.fieldBox}>
                <div className={styles.text}>{t('common.hours')}</div>
                <Input.Mask
                  ref={hoursField}
                  name="hours"
                  value={data.hours}
                  mask="9999"
                  maskChar={null}
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.fieldBox}>
                <div className={styles.text}>{t('common.minutes')}</div>
                <Input.Mask
                  ref={minutesField}
                  name="minutes"
                  value={data.minutes}
                  mask="99"
                  maskChar={null}
                  disabled={!isEditing}
                  onChange={handleFieldChange}
                />
              </div>
              <Button
                type="button"
                icon={isEditing ? 'close' : 'edit'}
                className={styles.iconButton}
                onClick={handleToggleEditingClick}
              />
            </div>
            {isEditing && <Button positive content={t('action.save')} />}
          </Form>
          <br />
          <p>Consuntivi</p>
          <div
            style={{
              minHeight: '50px',
              maxHeight: '250px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            {defaultValue &&
              Array.isArray(defaultValue) &&
              defaultValue.map((item) => (
                <StopwatchItem name={item.userFullName} date={item.startedAt} time={item.total} />
              ))}
          </div>
        </Popup.Content>
      </>
    );
  },
);

StopwatchEditStep.propTypes = {
  defaultValue: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

StopwatchEditStep.defaultProps = {
  defaultValue: undefined,
  onBack: undefined,
};

export default StopwatchEditStep;
